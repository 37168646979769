.UnitEdit .el-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.UnitEdit .el-form .el-form-item {
  width: 100%;
  margin-bottom: 22px;
}
.el-textarea__inner {
  min-height: 100px !important;
  resize: none;
}
.el-input-number {
  width: 100%;
}
